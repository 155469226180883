<script lang="js">
    import {responsive} from '../../state/responsive';
    import {readonly} from '../../state/readonly';

    import {setupImagePlaceholders} from '../../utils/images';

    import MedianetLogoSvg from '../svg/MedianetLogoSvg.vue';
    import GlobalNavigationContainer from './global-nav/Container.vue';
    import LocalNavigationContainer from './local-nav/Container.vue';
    import CurrentPortal from './global-nav/CurrentPortal.vue';
    import PopupPane from './popup-nav/Pane.vue';
    import PopupNavigation from './popup-nav/Navigation.vue';

    function instanceHasLocalNav(instance) {
        if (readonly.currentPortal === 'blackboard') {
            return false;
        }
        return !!(instance.main_menu || instance.secondary_menu || instance.user_menu || instance.search_form);
    }

    export default {
        components: {
            MedianetLogoSvg,
            GlobalNavigationContainer,
            LocalNavigationContainer,
            CurrentPortal,
            PopupPane,
            PopupNavigation,
        },
        data() {
            return {
                responsive,
                readonly,
                popupNavigationIsVisible: false,
            };
        },
        props: {
            title: String,
            brand: Object,
            logo: Object,
            date_element: String,
            logo_dropdown: Array,
            main_menu: Array,
            main_menu_section_title: String,
            secondary_menu: Array,
            search_form: Object,
            user_menu: Array,
            tabs: Array
        },
        computed: {
            hasLocalNav() {
                return instanceHasLocalNav(this);
            },
        },
        setup: function(props) {
            document.body.classList.add('standarized-nav--loaded');
            if (instanceHasLocalNav(props)) {
                document.body.classList.add('standarized-nav--has-local-nav');
            }
        },
        updated: function() {
            setupImagePlaceholders(this.$el);
            console.log('<Header> UPDATED', this, this.$el);
        },
        methods: {
            togglePopupNavigation(event) {
                event.preventDefault();
                this.popupNavigationIsVisible = !this.popupNavigationIsVisible;
                console.log('menu CLICK', event);
            },
            onPopupPaneClose(event) {
                console.log('onPopupPaneClose', event);
                this.togglePopupNavigation(event);
            },
        },
    }
</script>

<template>
    <header class="standardized-nav-container">
        <nav id="header-global-nav">
            <div>
                <a id="header-nav-logo" alt="medianet.at logo" href="/">
                    <MedianetLogoSvg />
                </a>
                <GlobalNavigationContainer v-if="responsive.isDesktop" :tabs="tabs" />
                <CurrentPortal v-if="responsive.isMobile" :tabs="tabs" />
                <button v-if="responsive.isMobile" aria-label="Website-Menü" @click="togglePopupNavigation">
                    <span class="material-symbols-outlined" role="presentation">menu</span>
                    Menü
                </button>
            </div>
        </nav>
        <nav id="header-local-nav" v-if="hasLocalNav && responsive.isDesktop">
            <div>
                <LocalNavigationContainer
                    :main_menu="main_menu"
                    :secondary_menu="secondary_menu"
                    :user_menu="user_menu"
                    :search_form="search_form"
                />
            </div>
        </nav>
        <PopupPane v-if="responsive.isMobile && popupNavigationIsVisible" @pane-close="onPopupPaneClose">
            <PopupNavigation
                :tabs="tabs"
                :main_menu="main_menu"
                :main_menu_section_title="main_menu_section_title"
                :secondary_menu="secondary_menu"
                :user_menu="user_menu"
                :search_form="search_form"
            ></PopupNavigation>
        </PopupPane>
    </header>
</template>

<style lang="scss" scoped>
    header {
        background: $header-global-nav-background-color;
        color: #fff;
        nav {
            > div {
                max-width: $max-container-width;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
            }
            &#header-global-nav {
                > div {
                    height: $header-global-nav-height;
                }
                a#header-nav-logo {
                    display: block;
                    font-size: 25px;
                    padding: 0 0.75em;
                    svg {
                        height: 1em;
                        display: block;
                    }
                }
                button {
                    display: none;
                    padding: 0;
                    width: $header-global-nav-height;
                    height: $header-global-nav-height;
                    color: $header-global-nav-text-color;
                    background: transparent;
                    border: none;
                    font-size: 0.7em;
                    font-family: $font-family-sans-serif-secondary;
                    font-stretch: 75%;
                    line-height: 1;
                    cursor: pointer;
                    span {
                        line-height: 0.8;
                        display: block;
                    }
                    &:hover {
                        color: $header-global-nav-text-highlight-color;
                    }
                }
                @media screen and (max-width: 1199px) {
                    justify-content: flex-start;
                    a#header-nav-logo {
                        font-size: 20px;
                    }
                    ul {
                        display: none;
                    }
                    button {
                        margin-left: auto;
                        display: block;
                    }
                }
            }
            &#header-local-nav {
                height: $header-local-nav-height;
                background: $header-local-nav-background-color;
                @media screen and (max-width: 1199px) {
                    display: none;
                }
            }
        }
    }
</style>
