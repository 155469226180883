<script lang="js">
    export default {
        props: {
            config: Object,
        },
        setup(props) {
            console.log('Search::props', props);
        },
    }
</script>

<template>
    <form :action="config.action_url">
        <div id="header-popup-search-text-input-container">
            <input
                type="text"
                :name="config.main_search_input?.key"
                :placeholder="config.main_search_input?.placeholder"
                ref="searchTextInput"
                tabindex="1"
            >
        </div>
        <!-- <select v-if="config.main_categories" :name="config.main_categories_value" tabindex="2">
            <option
                v-for="category in config.main_categories"
                :value="category.value"
            >{{ category.label }}</option>
        </select> -->
        <div id="header-popup-search-btn-container">
            <button tabindex="3" aria-label="Suche starten">
                <span class="material-symbols-outlined" role="presentation">search</span>
            </button>
        </div>
    </form>
</template>

<style lang="scss" scoped>
    form {
        background: rgba(255, 255, 255, 0.95);
        border-radius: 7px;
        margin: 0 0.3em 2em 0.3em;
        padding-right: 50px;
        position: relative;
        input[type=text], select {
            height: 36px;
            width: 100%;
            color: #333;
            padding: 0;
            border: 0;
            outline: 0;
            background: transparent;
        }
        input[type=text] {
            padding: 0 0.7em;
            &:focus,
            &:active {
                color: #333;
            }
            &::placeholder {
                color: #666;
            }
        }
        #header-popup-search-btn-container {
            button {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0;
                border: 0;
                height: 32px;
                width: 40px;
                background: rgba(0, 0, 0, 0.2);
                margin: 2px;
                border-radius: 5px;
                outline: 0;
                cursor: pointer;
                span {
                    line-height: 28px;
                    color: #fff;
                }
                &:hover,
                &:active,
                &:focus {
                    background: #444;
                    span {
                        color: $header-local-nav-text-highlight-color;
                    }
                }
            }
        }
        &:focus-within {
            outline: 2px solid #666;
            outline-offset: 1px;
            #header-popup-search-btn-container {
                button {
                    color: #fff;
                    background: #888;
                    &:hover,
                    &:active,
                    &:focus {
                        background: $header-local-nav-background-color;
                        span {
                            color: $header-local-nav-text-highlight-color;
                        }
                    }
                }
            }
        }
    }
</style>
