<script lang="js">
    import MenuItem from './MenuItem.vue'

    export default {
        components: {
            MenuItem,
        },
        props: {
            tabs: Array,
        },
        setup(props) {
            console.log('<global/Container />', props);
        }
    }
</script>

<template>
    <ul>
        <MenuItem v-for="tab in tabs" :tab="tab" />
    </ul>
</template>

<style lang="scss" scoped>
    ul {
        display: flex;
        justify-content: flex-start;
        height: $header-global-nav-height;
    }
</style>
