<script lang="js">
    import {responsive} from '../../../state/responsive';
    import Menu from '../local-nav/Menu.vue';

    export default {
        props: {
            isActive: Boolean,
            config: Object,
        },
        data() {
            return {
                responsive,
            };
        },
        components: {
            Menu,
        },
        emits: [
            'searchBarClose',
        ],
        computed: {
            getClassName() {
                if (this.isActive) {
                    return 'is-active';
                }
                return '';
            },
        },
        setup(props) {
            console.log('SearchBar::props', props);
        },
        updated() {
            if (this.isActive) {
                this.$refs.searchTextInput.focus();
            }
        }
    }
</script>

<template>
    <section :class="getClassName">
        <form :action="config.action_url">
            <div id="header-local-nav-searchbar-search-text-input-container">
                <input
                    type="text"
                    :name="config.main_search_input?.key"
                    :placeholder="config.main_search_input?.placeholder"
                    ref="searchTextInput"
                    tabindex="1"
                >
            </div>
            <select v-if="config.main_categories" :name="config.main_categories_value" tabindex="2">
                <option
                    v-for="category in config.main_categories"
                    :value="category.value"
                >{{ category.label }}</option>
            </select>
            <div id="header-local-nav-searchbar-search-btn-container">
                <button tabindex="3">
                    <span class="material-symbols-outlined">search</span>
                </button>
            </div>
        </form>
        <Menu v-if="config.extra_links" :content="config.extra_links" class="header-local-nav-searchbar-extra-links" />
        <span
            class="material-symbols-outlined"
            id="header-local-nav-searchbar-close-btn"
            @click="$emit('searchBarClose')"
            @keydown="$emit('searchBarClose')"
            tabindex="4"
        >close</span>
    </section>
</template>

<style lang="scss" scoped>
    section {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: $header-local-nav-background-color;
        opacity: 0;
        display: none;
        transition: all 150ms ease-in-out;
        will-change: opacity, bottom, transform;
        &.is-active {
            display: flex;
            opacity: 1;
            justify-content: center;
        }
    }
    form {
        font-size: 15px;
        display: flex;
        margin: auto 2em;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        #header-local-nav-searchbar-search-text-input-container {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0.2em;
                bottom: 0.2em;
                width: 1px;
                background: rgba(255, 255, 255, 0.1);
            }
        }
        input[type=text], select {
            height: 32px;
            color: #ccc;
            padding: 0;
            border: 0;
            margin: 0;
            outline: 0;
            background: transparent;
            box-shadow: none;
        }
        input[type=text] {
            width: 360px;
            padding: 0 0.7em;
            &:focus,
            &:active {
                color: #fff;
            }
            &::placeholder {
                color: #aaa;
            }
        }
        select {
            margin: 0 0.7em;
            appearance: auto;
            &:focus,
            &:active {
                color: #fff;
            }
            option {
                font-size: 12px;
                color: initial;
            }
        }
        #header-local-nav-searchbar-search-btn-container {
            button {
                padding: 0;
                border: 0;
                height: 28px;
                width: 60px;
                background: rgba(255, 255, 255, 0.2);
                margin: 2px;
                border-radius: 5px;
                box-shadow: none;
                outline: 0;
                cursor: pointer;
                span {
                    line-height: 28px;
                    color: #ccc;
                }
                &:hover,
                &:active,
                &:focus {
                    background: rgba(255, 255, 255, 0.3);
                    span {
                        color: $header-local-nav-text-highlight-color;
                    }
                }
            }
        }
        &:focus-within {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.8);
        }
    }
    #header-local-nav-searchbar-close-btn {
        position: absolute;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
        width: 1.5em;
        height: 1.5em;
        font-size: 20px;
        line-height: 1.5em;
        text-align: center;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 99%;
        cursor: pointer;
        color: #ccc;
        outline: 0;
        &:hover,
        &:focus,
        &:active {
            background: rgba(255, 255, 255, 0.2);
            color: #fff;
        }
    }
</style>
