<script lang="js">
    import MenuItemDropdown from './MenuItemDropdown.vue';
    export default {
        props: {
            tab: Object
        },
        components: {
            MenuItemDropdown,
        },
        computed: {
            hasDropdownClass() {
                return this.tab.dropdown_content? 'has-dropdown' : null;
            },
            calculatedClassName() {
                return [
                    this.hasDropdownClass,
                    this.tab.current? 'current' : '',
                ].filter(v => v).join(' ');
            }
        },
    }
</script>

<template>
    <li :class="calculatedClassName" v-if="tab.active">
        <a :href="tab.url">
            <span>{{ tab.label }}</span>
        </a>
        <MenuItemDropdown v-if="tab.dropdown_content" :content="tab.dropdown_content" />
    </li>
</template>

<style lang="scss" scoped>
    li {
        > a {
            position: relative;
            display: block;
            line-height: $header-global-nav-height;
            padding: 0 1.5em;
            color: $header-global-nav-text-color;
            text-decoration: none;
            font-size: 16px;
            text-transform: uppercase;
            span {
                line-height: 1;
                position: relative;
            }
            &:hover {
                color: $header-global-nav-text-highlight-color;
            }
        }
        &.current {
            background: $header-local-nav-background-color;
            > a {
                span::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background: $header-global-nav-text-highlight-color;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
        &.has-dropdown {
            .menu-item-dropdown {
                display: none;
            }
            > a::after {
                position: absolute;
                z-index: 3;
                content: "";
                bottom: -20px;
                left: 50%;
                width: 0;
                height: 0;
                border-style: solid;
                opacity: 0;
                transform: scale(0);
                transition: all 150ms ease-in-out;
                will-change: opacity, bottom, transform;
                margin-left: -12px;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #fff transparent;
            }
            &:hover {
                .menu-item-dropdown {
                    display: block;
                }
                > a {
                    span::after {
                        opacity: 0;
                    }
                    &::after {
                        opacity: 1;
                        transform: scale(1);
                        bottom: 0;
                    }
                }
            }
        }
    }
</style>
