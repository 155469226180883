<script lang="js">
    import Menu from './Menu.vue';
    import SearchBar from './SearchBar.vue';

    export default {
        components: {
            Menu,
            SearchBar,
        },
        data() {
            return {
                searchBarIsActive: false,
            };
        },
        props: {
            main_menu: Array,
            secondary_menu: Array,
            user_menu: Array,
            search_form: Object,
        },
        computed: {
            searchMenuContent() {
                return [{
                    title: 'Suche',
                    url: '#',
                    icon: 'search',
                }];
            }
        },
        methods: {
            onSearchMenuItemClicked(originalClickEvent) {
                originalClickEvent.preventDefault();
                console.log('SearchMenuItemClicked(); originalClickEvent', originalClickEvent);
                this.searchBarIsActive = true;
            },
            onSearchBarClose() {
                this.searchBarIsActive = false;
            },
        },
        setup(props) {
            console.log('<local-nav/Container />', props);
        },
    }
</script>

<template>
    <Menu
        v-if="main_menu"
        class="header-local-nav-main"
        :content="main_menu"
    />
    <Menu
        v-if="secondary_menu"
        class="header-local-nav-secondary"
        :content="secondary_menu"
    />
    <Menu
        v-if="search_form"
        class="header-local-nav-search"
        :show-icons="true"
        :content="searchMenuContent"
        @menu-item-click="onSearchMenuItemClicked"
    />
    <Menu
        v-if="user_menu"
        class="header-local-nav-user"
        :show-icons="true"
        :content="user_menu"
    />
    <SearchBar
        v-if="search_form"
        :config="search_form"
        :is-active="searchBarIsActive"
        @search-bar-close="onSearchBarClose"
    />
</template>
