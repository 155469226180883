<script lang="js">
    export default {
        props: {
            tabs: Array,
        },
        setup(props) {
            console.log('<popup-nav/Portals />', props);
        },
        computed: {
            activeTabs() {
                return this.tabs.filter(tab => tab.active);
            }
        },
        methods: {
            figureOutItemClass(item) {
                return item.current ? 'current' : null;
            },
        },
    }
</script>

<template>
    <div class="header-nav-pane-section-title">Portal auswählen</div>
    <ul>
        <template v-for="tab in activeTabs">
            <li :class="figureOutItemClass(tab)">
                <a :href="tab.url">
                    {{ tab.label }}
                </a>
            </li>
        </template>
    </ul>
</template>

<style lang="scss" scoped>
    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75em;
        padding: 0 0.3em;
        margin-bottom: 2em;
        li {
            display: inline-block;
            line-height: 1.75em;
            background: $header-local-nav-background-color;
            color: $header-global-nav-text-color;
            border-radius: 5px;
            a {
                display: block;
                color: $header-global-nav-text-color;
                text-decoration: none;
                padding: 0.2em 1em;
                font-size: 0.95em;
                text-transform: uppercase;
                &:hover {
                    text-decoration: underline;
                    color: $header-global-nav-text-highlight-color;
                }
            }
            &.current {
                a {
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    text-decoration-thickness: 2px;
                    color: $header-global-nav-text-highlight-color;
                }
            }
        }
    }
</style>
