import MD5 from 'md5-es';

export function setupImagePlaceholders(container)
{
    // Make sure that images that cannot be loaded have a visually pleasant placeholder.
    container.querySelectorAll('img:not(.placeholder-listener-set-up)').forEach(function(img) {
        img.classList.add('placeholder-listener-set-up');
        img.addEventListener('error', function() {
            img.classList.add('use-placeholder');
            // Use a 1x1 transparent pixel, so we don't have that ugly error icon.
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIABAAAAAP///yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
        });
    });
}

/**
 * Build a thumbnail URL given the original image URL and the thumbnail version.
 * @param {String} originalImageUrl
 * @param {String} thumbnailVersion
 * @returns {String}
 */
export function thumbnailUrl(originalImageUrl, thumbnailVersion)
{
    // Temporary hack until AVIF support is fully implemented. Append the ".jpg" to extension-less vimeocdn URLs.
    let extension = '';
    if (originalImageUrl.indexOf('i.vimeocdn.com') !== -1) {
        if (originalImageUrl.indexOf('.jpg') === -1) {
            extension = '.jpg';
        }
    }

    // Build and return the thumbnail URL.
    return import.meta.env.VITE_IMAGE_THUMBNAIL_BASE_URL
        + 's/i/t/'
        + createThumbnailMd5Path(originalImageUrl)
        + '/' + thumbnailVersion + '/'
        + originalImageUrl.replace('https://', 'https/').replace('http://', 'http/')
        + extension;
}

function createThumbnailMd5Path(imageUrl)
{
    let md5Hash = MD5.hash(imageUrl);
    let path = '';
    for (let i = 0; i < 3; i++) {
        path += md5Hash[i] + '/';
    }
    path += md5Hash.slice(3);
    return path;
}
