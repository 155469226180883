<script lang="js">
    export default {
        props: {
            tabs: Array,
        },
        setup(props) {
            console.log('<global/CurrentPortal />', props);
        }
    }
</script>

<template>
    <template v-for="tab in tabs" :tab="tab">
        <a v-if="tab.current" :href="tab.url">
            <span>{{ tab.label }}</span>
        </a>
    </template>
</template>

<style lang="scss" scoped>
    a {
        display: block;
        line-height: calc($header-global-nav-height - 14px);
        padding: 0 1em;
        min-width: 50px;
        box-sizing: border-box;
        color: $header-global-nav-text-color;
        text-decoration: none;
        font-size: 16px;
        text-transform: uppercase;
        background: $header-local-nav-background-color;
        border-radius: 5px;
        span {
            line-height: 1;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                height: 2px;
                background: $header-global-nav-text-highlight-color;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        &:hover {
            color: $header-global-nav-text-highlight-color;
        }
    }
</style>
