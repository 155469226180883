<script lang="js">
    export default {
        setup(props) {
            console.log('<popup-nav/Pane />', props);
        },
        emits: [
            'PaneClose',
        ]
    }
</script>

<template>
    <aside id="medianet-header-nav-pane">
        <div class="pane-header">
            <span
                id="pane-close-btn"
                class="material-symbols-outlined"
                @click="$emit('PaneClose', $event)"
                tabindex="0"
                aria-label="Menü schließen"
            >close</span>
        </div>
        <nav><slot /></nav>
    </aside>
</template>

<style lang="scss">
    #medianet-header-nav-pane .header-nav-pane-section-title {
        color: #bbb;
        font-size: 0.85em;
        font-weight: 300;
        text-transform: uppercase;
        padding-left: 0.352941176em;
        margin-bottom: 0.6em;
    }
</style>

<style lang="scss" scoped>
    aside {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: #000;
        div.pane-header {
            height: $header-global-nav-height;
            top: 0;
            left: 0;
            width: 100%;
            position: absolute;
            span {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 1.5em;
                height: 1.5em;
                font-size: 20px;
                line-height: 1.5em;
                text-align: center;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 99%;
                cursor: pointer;
                color: #ccc;
                outline: 0;
                &:hover,
                &:focus,
                &:active {
                    background: rgba(255, 255, 255, 0.2);
                    color: #fff;
                }
                &#pane-close-btn {
                    right: 0.5em;
                }
            }
        }
        > nav {
            position: absolute;
            top: $header-global-nav-height;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 1em;
            overflow-y: auto;
        }
    }
</style>
