<script lang="js">
    export default {
        props: {
            content: Object,
        }
    }
</script>

<template>
    <div>
        <h2>{{ content.title }}</h2>
        <p>{{ content.content }}</p>
        <a v-if="content.cta_label" :href="content.cta_url">
            {{ content.cta_label }}
            <span class="material-symbols-outlined">arrow_forward</span>
        </a>
    </div>
</template>

<style lang="scss" scoped>
    :global(.standardized-nav-container .layout-intro) {
        max-width: 30%;
        color: #555;
        order: 1 !important;
        display: flex;
        align-items: center;
        background: #f0f0f0;
        margin-top: -15px;
        margin-left: -25px;
        margin-bottom: -15px;
        line-height: 1.3;
        border-radius: 7px 0 0 7px;
        padding: 15px 25px;
    }
    h2 {
        font-size: 16px;
        line-height: 1.3;
        font-weight: 700;
        margin-bottom: 1em;
        color: #222;
    }
    p {
        margin-bottom: 1em;
        line-height: 1.3;
    }
    a {
        text-decoration: none;
        background: $header-local-nav-background-color;
        color: #eee;
        display: inline-block;
        padding: 0.6em 1em;
        line-height: 1;
        border-radius: 5em;
        font-size: 14px;
        span {
            font-size: inherit;
            vertical-align: middle;
            position: relative;
            transition: left 150ms ease-in-out;
            left: 0;
        }
        &:hover {
            background: $header-global-nav-background-color;
            color: $header-local-nav-text-highlight-color;
            span {
                left: 0.25em;
            }
        }
    }
</style>
