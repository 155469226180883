<script lang="js">
    import {thumbnailUrl} from '../../../utils/images';

    export default {
        props: {
            content: Object,
        },
        methods: {
            figureOutThumbnailVersion(item) {
                switch (item.type) {
                    case 'mn_news_article':
                    case 'mn_tv_video':
                    case 'mn_blackboard_ad':
                        return 1;
                    case 'mn_bizdates_event':
                        return 2;
                    default:
                        return 'proxy';
                }
            },
            figureOutThumbnailUrl(item) {
                return thumbnailUrl(item.image, this.figureOutThumbnailVersion(item));
            },
            itemDetails(item) {
                if (item.type === 'mn_bizdates_event') {
                    return item.acf.event_start_date + '<br>' + item.acf.event_location;
                }
                return item.text;
            },
        },
    }
</script>

<template>
    <h2>{{ content.label }}</h2>
    <ul>
        <li v-for="item in content.items" :data-type="item.type">
            <a :href="item.url">
                <img :src="figureOutThumbnailUrl(item)" :alt="item.title">
                <div>
                    <strong>{{ item.title }}</strong>
                    <p v-html="itemDetails(item)"></p>
                </div>
            </a>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    h2 {
        font-size: 20px;
        line-height: normal;
        font-weight: 500;
        margin-bottom: 0.15em;
        color: #222;
    }
    ul {
        li {
            padding: 1em 0;
            border-bottom: 1px dotted #ddd;
            &:last-child {
                border-bottom: 0;
            }
            a {
                display: flex;
                justify-content: start;
                align-items: center;
                color: #555;
                text-decoration: none;
                max-width: 450px;
                img {
                    width: 80px;
                    height: 80px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    aspect-ratio: 1;
                    object-fit: cover;
                    border-radius: 5px;
                    margin-right: 10px;
                    font-size: 0.7em;
                    &.use-placeholder {
                        background: #eee;
                        display: flex;
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        padding: 0.5em;
                        text-align: center;
                    }
                }
                div {
                    height: 100%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    strong {
                        display: block;
                        font-weight: 700;
                        line-height: 1.3;
                        margin-bottom: 0.5em;
                    }
                    p {
                        font-family: $font-family-sans-serif-secondary;
                        font-size: 84%;
                        font-weight: 500;
                        font-stretch: 87%;
                        line-height: 1.3;
                    }
                }
                &:hover {
                    div {
                        strong {
                            text-decoration: underline;
                        }
                    }
                }
            }
            &[data-type=mn_blackboard_ad],
            &[data-type=mn_news_article],
            &[data-type=mn_tv_video] {
                a {
                    img {
                        width: 120px;
                        aspect-ratio: 3/2;
                    }
                }
            }
        }
    }
    .use-2-columns,
    .use-3-columns {
        ul {
            column-gap: 1.5em;
        }
    }
    .use-2-columns ul {
        column-count: 2;
    }
    .use-3-columns ul {
        column-count: 3;
    }
</style>
