import { reactive } from 'vue';

export const responsive = reactive({
    viewportType: null,
    isDesktop: null,
    isMobile: null,
    init() {
        console.log('responsive.init()');
        this.calculateVariables();
        window.addEventListener('resize', this.windowResizeCallback.bind(this));
    },
    destroy() {
        window.removeEventListener('resize', this.windowResizeCallback.bind(this));
    },
    windowResizeCallback() {
        this.calculateVariables();
    },
    calculateVariables() {
        const viewportWidth = document.documentElement.clientWidth;
        if (viewportWidth < 1200) {
            this.viewportType = 'mobile';
        }
        else {
            this.viewportType = 'desktop';
        }
        this.isDesktop = this.viewportType === 'desktop';
        this.isMobile = this.viewportType === 'mobile';
        console.log('calculateVariables', viewportWidth, this.viewportType, this.isDesktop, this.isMobile);
    },

    /**
     * Check if the given responsive rule (it can be a list of multiple rules / tokens) respects a given viewport type.
     * Example responsive rules: "desktop-only", "mobile-only", "tablet-only mobile-only".
     * @param {String} responsiveRule
     * @returns {boolean}
     */
    responsiveRuleRespectsViewportType(responsiveRule, viewportType) {
        // No "responsive" restrictions means it (most probably some element) should be visible.
        if (!responsiveRule) {
            return true;
        }
        if (!viewportType) {
            viewportType = this.viewportType;
        }
        // Otherwise, it should be visible only if it has the correct "xyz-only" value (e.g. "desktop-only" or "mobile-only").
        return responsiveRule.indexOf(viewportType + '-only') > -1;
    },

    /**
     * Check if an element should be visible based on its responsive rule, if any, and on the currently active viewport type.
     * @param {String} responsiveRule
     * @returns {boolean}
     */
    shouldBeVisible(responsiveRule) {
        return this.responsiveRuleRespectsViewportType(responsiveRule, this.viewportType);
    }
});
