<script lang="js">
    export default {
        components: {
        },
        props: {
            footer_menu_links: Array,
            copyright: String,
        },
        setup(props) {
            console.log('Footer::setup()', props)
        }
    }
</script>

<template>
    <footer class="standardized-nav-container">
        <div>
            <ul v-if="footer_menu_links">
                <li v-for="link in footer_menu_links">
                    <a :href="link.url">{{ link.title }}</a>
                </li>
            </ul>
            <p v-if="copyright">{{ copyright }}</p>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
    footer {
        font-family: $font-family-sans-serif-primary;
        font-size: 15px;
        color: #eee;
        background: $footer-background-color;
        padding: 0 1em;
        > div {
            max-width: $max-container-width;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $header-global-nav-height;
            position: relative;
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: flex-start;
                li {
                    list-style: none;
                    margin-right: 2em;
                    a {
                        color: #eee;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            p {
                font-weight: 300;
            }
            @media all and (max-width: 1199px) {
                height: auto;
                flex-direction: column;
                align-items: center;
                padding: 1em 0;
                ul {
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-bottom: 0.7em;
                    li {
                        text-align: center;
                        margin-right: 0;
                        a {
                            display: block;
                            padding: 0.25em 1em;
                        }
                    }
                }
                p {
                    font-family: $font-family-sans-serif-secondary;
                    font-size: 87%;
                    font-stretch: 84%;
                }
            }
            @media all and (max-width: 480px) {
                li {
                    width: 50%;
                }
            }
        }
    }
</style>
