<script lang="js">
    import {responsive} from '../../../state/responsive';

    export default {
        props: {
            sectionTitle: String,
            content: Array,
            showIcons: Boolean,
            isAlwaysOpen: Boolean,
        },
        data() {
            return {
                responsive,
            };
        },
        emits: [
            'menuItemClick',
        ],
        setup(props) {
            console.log('<popup-nav/Menu />', props);
        },
        methods: {
            figureOutItemClass(item) {
                return [
                    item.children? 'has-children' : '',
                    item.isOpen? 'is-open' : '',
                    item.isAlwaysOpen? 'is-always-open' : '',
                ].filter(v => v).join(' ');
            },
            onMenuItemClick(event, item) {
                console.log(event, item.children);
                event.stopPropagation();
                if (item.children) {
                    event.preventDefault();
                    item.isOpen = !item.isOpen;
                }
            },
        },
    }
</script>

<template>
    <div class="header-nav-pane-section-title" v-if="sectionTitle"> {{ sectionTitle }}</div>
    <ul>
        <template v-for="item in content">
            <li v-if="responsive.shouldBeVisible(item.responsive)" :class="figureOutItemClass(item)" @click="onMenuItemClick($event, item)">
                <a :href="item.url">
                    <span class="material-symbols-outlined" v-if="showIcons && item.icon">{{ item.icon }}</span>
                    {{ item.title }}
                </a>
                <Menu v-if="item.children" :content="item.children" :show-icons="showIcons" />
            </li>
        </template>
    </ul>
</template>

<style lang="scss" scoped>
    ul {
        margin-bottom: 1em;
        li {
            position: relative;
            color: $header-global-nav-text-color;
            line-height: 1.75em;
            border-top: 1px dotted #777;
            &:first-child {
                border-top-width: 0;
            }
            a {
                display: block;
                color: $header-global-nav-text-color;
                text-decoration: none;
                padding: 0.1em 0.3em;
                &:hover {
                    text-decoration: underline;
                    color: $header-global-nav-text-highlight-color;
                }
            }
            &.has-children {
                &::before,
                &::after {
                    position: absolute;
                    top: 0.683em;
                    transform: translateY(-50%);
                    right: 0;
                    transition: opacity 500ms ease-in-out;
                }
                &::before {
                    @include pseudo-element-icon('chevron_right', 1.5em);
                    opacity: 1;
                }
                &::after {
                    @include pseudo-element-icon('unfold_less', 1.5em);
                    opacity: 0;
                }
                ul {
                    display: none;
                    margin-left: 0.1em;
                    font-weight: 300;
                    li {
                        border-top: 0;
                        a {
                            &::before {
                                @include pseudo-element-icon('arrow_right', 1.25em);
                                vertical-align: middle;
                            }
                        }
                    }
                }
                &.is-open {
                    &::before {
                        opacity: 0;
                    }
                    &::after {
                        opacity: 1;
                    }
                    ul {
                        display: block;
                    }
                }
                &.is-always-open {
                    &::before,
                    &::after {
                        display: none;
                    }
                    ul {
                        display: block;
                    }
                }
            }
        }
    }
</style>
