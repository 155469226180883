export function snake2CamelCase(string)
{
    return string.replace(/_(\w)/g, ($, $1) => $1.toUpperCase());
}

export function snake2PascalCase(string)
{
    let s = snake2CamelCase(string);
    return `${s.charAt(0).toUpperCase()}${s.substr(1)}`;
}
