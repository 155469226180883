<script lang="js">
    import MenuItemDropdownItem from './MenuItemDropdownItem.vue';
    export default {
        props: {
            content: Array,
        },
        components: {
            MenuItemDropdownItem
        },
    }
</script>

<template>
    <div class="menu-item-dropdown">
        <div>
            <MenuItemDropdownItem v-for="item in content" :content="item" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .menu-item-dropdown {
        position: absolute;
        top: $header-global-nav-height;
        left: 0;
        right: 0;
        height: 0;
        z-index: 2;
        > div {
            display: flex;
            width: 100%;
            background: #fff;
            padding: 15px 25px;
            border-radius: 7px;
            box-shadow: 0 0 1px #1f2d37, 0 0 10px rgba(0, 0, 0, 0.35);
            @media (min-width: $max-container-width) {
                width: $max-container-width;
                margin: 0 auto;
            }
        }
    }
</style>
