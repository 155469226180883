<script lang="js">
    import Menu from './Menu.vue';
    import Portals from './Portals.vue';
    import Search from './Search.vue';

    export default {
        components: {
            Menu,
            Portals,
            Search,
        },
        props: {
            tabs: Array,
            main_menu: Array,
            main_menu_section_title: String,
            secondary_menu: Array,
            user_menu: Array,
            search_form: Object,
        },
        setup(props) {
            console.log('<popup-nav/Navigation />', props);
        },
    }
</script>

<template>
    <Portals
        :tabs="tabs"
    />
    <Search
        v-if="search_form"
        :config="search_form"
    />
    <Menu
        v-if="main_menu"
        :content="main_menu"
        :section-title="main_menu_section_title"
    />
    <Menu
        v-if="secondary_menu"
        :content="secondary_menu"
    />
    <Menu
        v-if="user_menu"
        :show-icons="false"
        :content="user_menu"
    />
</template>
