<script lang="js">
    import MenuItem from './MenuItem.vue'

    export default {
        components: {
            MenuItem,
        },
        props: {
            content: Array,
            class: String,
            showIcons: Boolean,
        },
        emits: [
            'menuItemClick',
        ],
        methods: {
            onMenuItemClick(originalClickEvent) {
                this.$emit('menuItemClick', originalClickEvent);
            },
        },
        setup(props) {
            console.log('<local-nav/Menu />', props);
        },
    }
</script>

<template>
    <ul :class="class">
        <MenuItem v-for="item in content" :item="item" :show-icon="showIcons" @menu-item-click="onMenuItemClick" />
    </ul>
</template>

<style lang="scss" scoped>
    ul {
        display: flex;
        justify-content: flex-start;
        height: $header-local-nav-height;
        &.header-local-nav-main + ul {
            margin-left: auto;
        }
        &.header-local-nav-secondary {
        }
        &.header-local-nav-user {
        }
    }
</style>
