import { createApp, reactive, h } from 'vue';
import {responsive} from './state/responsive';
import { readonly } from './state/readonly';
import { normalizeNavigationConfig, setNagivationHeaderCurrentTab } from './utils/adapters';

import './assets/main.scss';

import Header from './components/header/Header.vue';
import Footer from './components/footer/Footer.vue';

responsive.init();

normalizeNavigationConfig(window.standardized_config);

readonly.currentPortal = window.standardized_config.current_portal;
document.body.classList.add('standardized-nav--' + window.standardized_config.current_portal);

let headerProps = window.standardized_config.header;
headerProps.tabs = [];
headerProps = reactive(headerProps);
createApp({
    render: () => h(Header, headerProps)
}).mount('#standardized-header-navbar');

let footerProps = window.standardized_config.footer;
footerProps = reactive(footerProps);
createApp({
    render: () => h(Footer, footerProps)
}).mount('#standardized-footer-navbar');

fetch(import.meta.env.VITE_GLOBAL_NAVIGATION_ENDPOINT)
    .then((response) => response.json())
    .then(function(data) {
        console.log('fetch', data);
        headerProps.tabs = data.header_tabs;
        setNagivationHeaderCurrentTab(headerProps.tabs);
    });
