<script lang="js">
    import {responsive} from '../../../state/responsive';
    import MenuItemDropdown from './MenuItemDropdown.vue';

    export default {
        props: {
            item: Object,
            showIcon: Boolean,
        },
        data() {
            return {
                responsive,
            };
        },
        components: {
            MenuItemDropdown: MenuItemDropdown,
        },
        computed: {
            hasDropdownClass() {
                return this.item.children? 'has-dropdown' : null;
            }
        },
        emits: [
            'menuItemClick',
        ],
        methods: {
            emitMenuItemClicked(originalClickEvent) {
                this.$emit('menuItemClick', originalClickEvent);
            },
        },
    }
</script>

<template>
    <li :class="hasDropdownClass" v-if="responsive.shouldBeVisible(item.responsive)">
        <a :href="item.url" @click="emitMenuItemClicked($event)">
            <span class="material-symbols-outlined" v-if="showIcon && item.icon">{{ item.icon }}</span>
            {{ item.title }}
        </a>
        <MenuItemDropdown v-if="item.children" :content="item.children" />
    </li>
</template>

<style lang="scss" scoped>
    li {
        position: relative;
        > a {
            position: relative;
            display: block;
            line-height: $header-local-nav-height;
            padding: 0 1.5em;
            color: $header-local-nav-text-color;
            text-decoration: none;
            font-size: 14px;
            text-transform: uppercase;
            white-space: nowrap;
            .material-symbols-outlined {
                line-height: 20px;
                vertical-align: text-top;
            }
            &:hover {
                color: $header-local-nav-text-highlight-color;
            }
        }
        &.has-dropdown {
            .menu-item-dropdown {
                display: none;
            }
            > a::after {
                position: absolute;
                z-index: 2;
                content: "";
                bottom: -20px;
                left: 50%;
                width: 0;
                height: 0;
                border-style: solid;
                opacity: 0;
                transform: scale(0);
                transition: all 150ms ease-in-out;
                will-change: opacity, bottom, transform;
                margin-left: -12px;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent #fff transparent;
            }
            &:hover {
                .menu-item-dropdown {
                    display: block;
                }
                > a::after {
                    opacity: 1;
                    transform: scale(1);
                    bottom: 0;
                }
            }
        }
    }
</style>
