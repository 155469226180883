<script lang="js">
    import MenuItemDropdownLinksGroup from './MenuItemDropdownLinksGroup.vue'
    import MenuItemDropdownIntro from './MenuItemDropdownIntro.vue';
    import MenuItemDropdownFeaturedContent from './MenuItemDropdownFeaturedContent.vue';
    import {snake2PascalCase} from '../../../utils/string';

    export default {
        components: {
            MenuItemDropdownLinksGroup,
            MenuItemDropdownIntro,
            MenuItemDropdownFeaturedContent,
        },
        props: {
            content: Object,
        },
        computed: {
            componentName() {
                return 'MenuItemDropdown' + snake2PascalCase(this.content.layout);
            },
            classList() {
                return [
                    'header-global-nav-menu-item-dropdown-layout',
                    'layout-' + this.content.layout,
                    this.content?.meta?.html_class_name
                ].filter(v => v).join(' ');
            },
        },
        setup(props) {
            console.log('MenuItemDropdownItem::setup()', props, props.content);
        },
    }
</script>

<template>
    <div :class="classList">
        <component :is="componentName" :content="content" />
    </div>
</template>

<style lang="scss" scoped>
    .header-global-nav-menu-item-dropdown-layout {
        margin-right: 2.5em;
        order: 9;
    }
</style>
