<script lang="js">
    export default {
        props: {
            content: Array,
        },
    }
</script>

<template>
    <ul class="menu-item-dropdown">
        <li v-for="item in content">
            <a :href="item.url">{{ item.title }}</a>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    .menu-item-dropdown {
        position: absolute;
        top: $header-local-nav-height;
        left: 0;
        z-index: 1;
        background: #fff;
        padding: 15px 25px;
        border-radius: 7px;
        box-shadow: 0 0 1px #1f2d37, 0 0 10px rgba(0, 0, 0, 0.35);
        white-space: nowrap;
        li {
            padding: 0.3em 0;
            border-bottom: 1px dotted #ddd;
            &:last-child {
                border-bottom: 0;
            }
            a {
                color: #555;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>
