<script lang="js">
    export default {
        props: {
            content: Object,
        }
    }
</script>

<template>
    <h2>{{ content.label }}</h2>
    <ul>
        <li v-for="link in content.links">
            <a :href="link.url">{{ link.label }}</a>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
    h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.15em;
        color: #222;
    }
    ul {
        li {
            padding: 0.3em 0;
            border-bottom: 1px dotted #ddd;
            &:last-child {
                border-bottom: 0;
            }
            a {
                color: #555;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .use-2-columns,
    .use-3-columns {
        ul {
            column-gap: 1.5em;
        }
    }
    .use-2-columns ul {
        column-count: 2;
    }
    .use-3-columns ul {
        column-count: 3;
    }
</style>
