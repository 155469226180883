export function normalizeNavigationConfig(config)
{
    normalizeNavigationHeaderConfig(config.header);

    // Set the current portal (fallback, if it's not explicitly set).
    if (!config.current_portal) {
        config.current_portal = getFirstPathToken(window.location.pathname);
        if (!config.current_portal) {
            config.current_portal = 'home';
        }
    }
}

function normalizeNavigationHeaderConfig(headerConfig)
{
    if (!headerConfig.user_menu) {
        return;
    }
    if (!headerConfig.user_menu[0]) {
        return;
    }
    // Don't modify when we have more than 1 user menu entry.
    if (headerConfig.user_menu.length > 1) {
        return;
    }
    // User menu labels that will not be changed.
    const allowedLabels = [
        'Login', 'Logout', 'Anmelden', 'Abmelden',
    ];
    // When the user menu item has a dropdown with children items in it, it is most probably a "Profile"-type main menu item, so
    // let's replace its label.
    if (headerConfig.user_menu[0].children && (headerConfig.user_menu[0].children.length > 0)) {
        if (allowedLabels.indexOf(headerConfig.user_menu[0].title) === -1) {
            headerConfig.user_menu[0].title = 'Profil';
        }
    }
    else {
        if (allowedLabels.indexOf(headerConfig.user_menu[0].title) === -1) {
            headerConfig.user_menu[0].title = 'Kunde';
        }
    }
    headerConfig.user_menu[0].icon = 'person';
}

function getFirstPathToken(path)
{
    const pathTokens = path.split('/');
    if (pathTokens.length < 2) {
        return '';
    }
    return pathTokens[1];
}

export function setNagivationHeaderCurrentTab(tabs)
{
    let longestTabUrlPathnameLength = -1;
    let bestCurrentTabIndexSoFar = -1;
    tabs.map(function(tab, index) {
        const tabUrlPathname = new URL(tab.url).pathname;
        if (window.location.pathname.indexOf(tabUrlPathname) === 0) {
            if (longestTabUrlPathnameLength < tabUrlPathname.length) {
                longestTabUrlPathnameLength = tabUrlPathname.length;
                bestCurrentTabIndexSoFar = index;
            }
        }
    });
    if (bestCurrentTabIndexSoFar > -1) {
        tabs[bestCurrentTabIndexSoFar].current = true;
    }
}
